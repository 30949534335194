import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SpotifyLogin from "./SpotifyLogin";
import SaveUserData from "./SaveUserData";
import RecommendConcerts from "./RecommendConcerts";
import ConcertsList from "./ConcertsList";
import { supabase } from "./supabaseClient"; // Ensure supabase is imported here
import "../blocks/App.css";

function App() {
  const [session, setSession] = useState(null);
  const [recommendations, setRecommendations] = useState([]);

  // Fix the handleLogout function to use supabase
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut(); // Make sure supabase is called here
    if (error) {
      console.error("Error during logout:", error.message);
    } else {
      setSession(null);
    }
  };

  return (
    <div className="page">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              !session ? (
                <SpotifyLogin session={session} setSession={setSession} />
              ) : (
                <Navigate to="/myconcerts" />
              )
            }
          />
          <Route
            path="/myconcerts"
            element={
              session ? (
                <>
                  <SaveUserData
                    session={session}
                    handleLogout={handleLogout} // Pass handleLogout properly
                  />
                  <RecommendConcerts
                    session={session}
                    setRecommendations={setRecommendations}
                  />
                  <ConcertsList recommendations={recommendations} />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
