import React, { useEffect } from "react";
import { supabase } from "./supabaseClient";
import SaveUserData from "./SaveUserData";
import spotifyLogo from "../images/spotify-logo-black.png";
import hyperlocalvibeslogo from "../images/hyperlocalvibes_logo.png";
import "../blocks/SpotifyLogin.css";

const SpotifyLogin = ({ session, setSession }) => {
  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error getting session:", error);
      } else {
        setSession(data.session);
        const accessToken = data.session?.access_token;
      }
    };

    getSession();
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [setSession]);

  const handleLogin = () => {
    supabase.auth.signInWithOAuth({
      provider: "spotify",
      options: { scopes: "user-read-private user-read-email user-top-read" },
    });
  };

  const handleLogout = () => {
    supabase.auth.signOut();
    setSession(null);
  };

  return (
    <div className="body-login">
      <div>
        {!session ? (
          <div>
            <div className="container-login">
              <div className="header-login">
                <img
                  src={hyperlocalvibeslogo}
                  alt="HyperLocalVibes Logo"
                  className="logo-login"
                />
                <h1>HyperLocalVibes</h1>
              </div>
              <h2 className="subittle-login">
                Descubre conciertos y bandas locales según tu música favorita en
                Spotify
              </h2>
              <button onClick={handleLogin}>
                <img src={spotifyLogo} alt="Spotify Logo" />
                Inicia sesión con Spotify
              </button>
            </div>
          </div>
        ) : (
          <div>
            <SaveUserData session={session} handleLogout={handleLogout} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SpotifyLogin;
