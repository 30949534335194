import React, { useEffect, useRef } from "react";
import { supabase } from "./supabaseClient";

const RecommendConcerts = ({ session, setRecommendations }) => {
  const isProcessing = useRef(false);

  useEffect(() => {
    const fetchRecommendations = async () => {
      if (!session || !session.user.id || isProcessing.current) return;
      isProcessing.current = true;

      try {
        const userId = session.user.id;

        // Obtener todos los conciertos
        const { data: concerts, error: fetchConcertsError } = await supabase
          .from("concerts")
          .select("*");
        if (fetchConcertsError) throw fetchConcertsError;

        // Obtener las recomendaciones existentes
        const {
          data: existingRecommendations,
          error: fetchRecommendationsError,
        } = await supabase
          .from("user_concert_recommendations")
          .select("*")
          .eq("user_id", userId);
        if (fetchRecommendationsError) throw fetchRecommendationsError;

        // Identificar los conciertos que no tienen valoración
        const recommendedConcertIds = existingRecommendations.map(
          (rec) => rec.concert_id
        );
        const newConcerts = concerts.filter(
          (concert) => !recommendedConcertIds.includes(concert.id)
        );

        if (newConcerts.length > 0) {
          // Obtener géneros favoritos del usuario desde Spotify
          const token = session.provider_token;
          const topArtistsResponse = await fetch(
            "https://api.spotify.com/v1/me/top/artists?time_range=medium_term&limit=10",
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (!topArtistsResponse.ok)
            throw new Error("Failed to fetch top artists.");

          const topArtistsData = await topArtistsResponse.json();
          const genres = topArtistsData.items.flatMap(
            (artist) => artist.genres
          );
          const artists = topArtistsData.items.map((artist) => artist.name);

          // Enviar petición a OpenAI para los nuevos conciertos
          const prompt = `
            You are an advanced music recommendation system with greate knowledge about music genres and music bands. Evaluate concerts based on the user's preferences and assign a rating for each.

            ### User Preferences:
            - Favorite genres: ${genres.join(", ")}.
             - Favorite artists: ${artists.join(", ")}.

            ### Concerts to Evaluate:
            ${newConcerts
              .map(
                (concert) =>
                  `Concert_Band: ${concert.bandname}, Band_Genre: ${concert.genre}, Venue: ${concert.venue}, Date: ${concert.day}, Time: ${concert.hour}, Price: ${concert.price}`
              )
              .join("\n")}
### Scoring Criteria:
1. **Artist match (50 points)**:
    - If the Concert_Band matches any artist in the user's Favorite Artists list, assign a base score of 50 and classify it as 'extreme'.
    - Free concerts with an artist match receive an additional 20 points, ensuring these are prioritized.

2. **Band_Genre match (up to 30 points)**:
    - Exact match with a favorite Band_Genre: +30 points.
    - Related Band_Genre: +40 points, depending on similarity assessed through semantic analysis.

3. **Strict penalties for unrelated Band_Genre**:
    - Band_Genre very unrelated to user's favorite genres: Immediate score of 0-10 points, and classify as 'low'.

4. **Price factor (up to 20 points)**:
    - Free concerts (0): +20 points.
    - Concerts under 15: +10 points.
    - Concerts between 15-35: -5 points.
    - Concerts over 35: -25 points.
    - Concerts over 100: -50 points.

5. **Final Classification**:
    - **99-100 or higher**: Super strong recommendation (super high match). Classify as 'extreme'.
    - **70-98**: Strong recommendation (high match). Classify as 'high'.
    - **31-69**: Moderate recommendation (medium match). Classify as 'medium'.
    - **0-24**: Weak recommendation (low match). Classify as 'low'.

6. **Additional adjustments**:
    - Free concerts with Band_Genre related match: +15 points.
    - Free concerts with an exact Band_Genre match but no artist match: Classify as 'high'.
    - Concerts with related Band_Genre and cost under 15: Classify as 'medium'.
    - Strict rules for "hard genres" (e.g., "hardcore", "post-", "metal") or very specific (e.g., "flamenco") : Assign 'low' unless there is an exact genre or artist match.
    - Ensure consistency across concerts of the same genre, adjusting for price and ensuring similar recommendations are aligned.
    - If multiple related genres align with the user's preferences, boost the score by 5 points.

            ### Output:
            Return only a plain list of ratings in the same order as the concerts provided. The possible ratings are 'extreme', 'high', 'medium', or 'low'.


              ### Output:
              Return only a plain list of ratings in the same order as the concerts provided. The possible ratings are 'extreme', 'high', 'medium', or 'low'.
          `;

          const aiResponse = await fetch(
            "https://api.openai.com/v1/chat/completions",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              },
              body: JSON.stringify({
                model: "gpt-4o-mini",
                messages: [
                  {
                    role: "system",
                    content:
                      "You are an assistant that evaluates concerts based on user preferences.",
                  },
                  { role: "user", content: prompt },
                ],
                max_tokens: 500,
                temperature: 0.7,
              }),
            }
          );

          const aiData = await aiResponse.json();

          // Procesar las recomendaciones para obtener solo 'high', 'medium', 'low'
          const rawResponse = aiData.choices[0]?.message?.content || "";
          const scores = rawResponse
            .split("\n")
            .map((line) => {
              const lowerLine = line.toLowerCase();
              if (lowerLine.includes("high")) return "high";
              if (lowerLine.includes("medium")) return "medium";
              if (lowerLine.includes("low")) return "low";
              return null; // Ignorar líneas no relacionadas
            })
            .filter(Boolean); // Eliminar entradas nulas

          // Procesar las recomendaciones para asegurar que los géneros coincidentes sean 'high'
          const recommendations = newConcerts.map((concert) => {
            const concertGenres = concert.genre
              .toLowerCase()
              .split(",")
              .map((g) => g.trim());
            const hasMatchingGenre = concertGenres.some((genre) =>
              genres.map((g) => g.toLowerCase()).includes(genre)
            );

            const aiScore = scores.shift() || "low"; // Tomar el puntaje de la IA
            const finalScore = hasMatchingGenre ? "high" : aiScore; // Priorizar 'high' si hay coincidencia

            return {
              user_id: userId,
              concert_id: concert.id,
              score: finalScore,
            };
          });

          const { error: insertError } = await supabase
            .from("user_concert_recommendations")
            .insert(recommendations);
          if (insertError) throw insertError;

          // Actualizar el estado con todas las recomendaciones
          setRecommendations([...existingRecommendations, ...recommendations]);
        } else {
          // Si no hay nuevos conciertos, usar las existentes
          setRecommendations(existingRecommendations);
        }
      } catch (error) {
        console.error("Error en la lógica de recomendaciones:", error);
      } finally {
        isProcessing.current = false;
      }
    };

    fetchRecommendations();
  }, [session, setRecommendations]);

  return null;
};

export default RecommendConcerts;
