import React, { useEffect, useState, useRef } from "react";
import { supabase } from "./supabaseClient";
import "../blocks/SaveUserData.css";
import hyperlocalvibeslogo from "../images/hyperlocalvibes_logo.png";

const SaveUserData = ({ session, handleLogout }) => {
  const [userData, setUserData] = useState(null);
  const [topArtists, setTopArtists] = useState([]);
  const [topGenres, setTopGenres] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isProcessing = useRef(false);

  useEffect(() => {
    const fetchSpotifyData = async () => {
      if (!session || !session.provider_token || isProcessing.current) return;
      isProcessing.current = true;
      setLoading(true);

      try {
        const token = session.provider_token;

        // Obtener datos del perfil de usuario desde la API de Spotify
        const userProfileResponse = await fetch(
          "https://api.spotify.com/v1/me",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        );

        if (!userProfileResponse.ok)
          throw new Error("Failed to fetch user data.");
        const userProfile = await userProfileResponse.json();
        setUserData(userProfile);

        // Obtener los artistas más escuchados
        const topArtistsResponse = await fetch(
          "https://api.spotify.com/v1/me/top/artists?time_range=medium_term&limit=10",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        );

        if (!topArtistsResponse.ok)
          throw new Error("Failed to fetch top artists.");
        const topArtistsData = await topArtistsResponse.json();
        const genres = Array.from(
          new Set(topArtistsData.items.flatMap((artist) => artist.genres))
        );

        setTopArtists(topArtistsData.items.map((artist) => artist.name));
        setTopGenres(genres);

        // Guardar o actualizar los datos en Supabase
        await saveUserDataToSupabase(userProfile, topArtistsData.items, genres);
      } catch (error) {
        console.error("Error fetching Spotify data:", error);
        setError("Failed to fetch Spotify data.");
      } finally {
        setLoading(false);
        isProcessing.current = false;
      }
    };

    const saveUserDataToSupabase = async (
      spotifyUserData,
      topArtists,
      topGenres
    ) => {
      try {
        const {
          id: spotifyId,
          display_name,
          email,
          country,
          followers,
        } = spotifyUserData;

        // Verificar si el usuario ya existe
        const { data: existingUser, error: fetchError } = await supabase
          .from("users")
          .select("*")
          .eq("spotify_id", spotifyId)
          .limit(1);

        if (fetchError) throw fetchError;

        if (existingUser && existingUser.length > 0) {
          // Actualizar usuario existente
          const { error: updateError } = await supabase
            .from("users")
            .update({
              name: display_name,
              email,
              country,
              followers: followers.total,
              top_artists: topArtists.map((artist) => artist.name).join(", "),
              top_genres: topGenres.join(", "),
            })
            .eq("spotify_id", spotifyId);

          if (updateError) throw updateError;
        } else {
          // Insertar nuevo usuario
          const { error: insertError } = await supabase.from("users").insert([
            {
              spotify_id: spotifyId,
              name: display_name,
              email,
              country,
              followers: followers.total,
              top_artists: topArtists.map((artist) => artist.name).join(", "),
              top_genres: topGenres.join(", "),
            },
          ]);

          if (insertError) throw insertError;
        }
      } catch (error) {
        console.error("Error saving/updating user in Supabase:", error);
        setError("Error saving/updating user data.");
      }
    };

    fetchSpotifyData();
  }, [session]);

  if (loading) return <p></p>;
  if (error) return <p>{error}</p>;

  return (
    userData && (
      <div className="header">
        <div className="user-data-container">
          <div className="user-data-header">
            <div className="user-data-branding">
              <div>
                <img
                  src={hyperlocalvibeslogo}
                  alt="HyperLocalVibes Logo"
                  className="user-data-logo"
                />
              </div>
              <div>
                <h2 className="user-data-brand">HyperLocalVibes</h2>
              </div>
            </div>
            <div className="user-data-information">
              {userData.images?.length > 0 && (
                <img
                  src={userData.images[0].url}
                  alt="User Avatar"
                  className="user-data-avatar"
                />
              )}
              <h2 className="user-data-name">{userData.display_name}</h2>
              <button
                className="user-data-logout-button"
                onClick={handleLogout}
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SaveUserData;
