import React, { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import "../blocks/ConcertsList.css";

const ConcertsList = ({ recommendations }) => {
  const [concerts, setConcerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConcerts = async () => {
      try {
        const today = new Date().toISOString().split("T")[0]; // Fecha actual en formato YYYY-MM-DD
        const { data, error: fetchError } = await supabase
          .from("concerts")
          .select("*")
          .gte("day", today); // Filtrar conciertos con fecha mayor o igual a hoy

        if (fetchError) throw fetchError;

        setConcerts(data);
      } catch (error) {
        console.error("Error fetching concerts data:", error);
        setError("Error fetching concerts data.");
      } finally {
        setLoading(false);
      }
    };

    fetchConcerts();
  }, []);

  // Function to get the correct emoji based on the recommendation score
  const getEmoji = (concert) => {
    if (!recommendations || recommendations.length === 0) return "🤔";

    const match = recommendations.find((rec) => rec.concert_id === concert.id);
    if (match) {
      switch (match.score) {
        case "extreme":
          return "🔥🔥🔥"; // High match
        case "high":
          return "🔥🔥"; // High match
        case "medium":
          return "🔥"; // Medium match
        case "low":
          return "❌"; // Low match
        default:
          return (
            <p className="loading-message">
              <span className="loading-emoji">🤔</span>
            </p>
          ); // Default while loading
      }
    }
    return "😶";
  };

  if (loading)
    return (
      <p className="loading-message">
        {/* <span className="loading-emoji">🤔</span> */}
      </p>
    );
  if (error) return <p className="error-message">{error}</p>;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: "long", day: "numeric", month: "long" };
    const formattedDate = date.toLocaleDateString("es-ES", options);

    // Capitaliza palabras excepto "de"
    return formattedDate
      .split(" ")
      .map((word) =>
        word.toLowerCase() === "de"
          ? word
          : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(" ");
  };

  return (
    <div className="cards-container">
      <h2 className="cards-title">Discover Upcoming Concerts</h2>
      <div className="cards-grid">
        {concerts.map((concert) => (
          <div key={concert.id} className="concert-card">
            <div className="card-header">
              <span className="concert-date">{formatDate(concert.day)}</span>
              <span className="concert-emoji">{getEmoji(concert)}</span>
            </div>
            <div className="card-body">
              <h3 className="concert-band">{concert.bandname}</h3>
              <p className="concert-genre">
                <strong>Género:</strong> {concert.genre}
              </p>
              <p className="concert-venue">
                <strong>Local:</strong> {concert.venue}
              </p>
              <p className="concert-time">
                <strong>Hora:</strong> {concert.hour}
              </p>
              <p className="concert-price">
                Precio: {concert.price === 0 ? "Gratis" : `${concert.price} €`}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConcertsList;
